import { createModel } from '@prodo/core'
import localPlugin from '@prodo/local'

export interface State {
  loadingUser: boolean
}

export interface LocalState {
  user: null | {
    id: string
    username: string
    email?: string
    token: string
    githubId?: string
    googleId?: string
    organisations: {
      id: string
      name: string
    }[]
  }
}

export let model = createModel<State>().with(localPlugin<LocalState>())

export const { state, watch, dispatch, local } = model.ctx
