module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lona","short_name":"Lona","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prodo/gatsby-browser.js'),
      options: {"plugins":[],"model":"/opt/build/repo/src/model","storeConfig":{"initState":{"loadingUser":false},"initLocal":{"user":null}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
