import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { local } from '../model'
import meQuery from './me.graphql'
import createOrgMutation from './createOrg.graphql'

export const client = new ApolloClient({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
  fetch,
})

export async function signInWithToken(token: string) {
  const { data } = await client.query({
    query: meQuery,
    context: {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  })
  local.user = data.getMe
}

export async function createOrg(name: string) {
  const { data } = await client.query<{
    success: boolean
    message: string
    organisation?: { name: string; id: string }
  }>({
    query: createOrgMutation,
    variables: {
      name,
    },
    context: {
      headers: {
        Authorization: 'Bearer ' + local.user.token,
      },
    },
  })
  if (data.success) {
    local.user.organisations.push(data.organisation)
  }
}
